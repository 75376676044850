import { graphql } from "gatsby"
import React from "react"
import FlexibleContent from "~/components/FlexibleLayouts"
import Layout from "~/components/Layout"
import Seo from "~/components/Seo"


function Default({ data: { wpPage } }) {
  let isDark = false
  let headerBg
  const flexibleContent = wpPage.page?.flexibleContent
  if (flexibleContent && flexibleContent[0]) {
    const sectionBg = flexibleContent[0]?.section?.backgroundColour
    if (sectionBg === "black" || sectionBg === "slate") {
      isDark = true
      headerBg = "bg-transparent"
    } else if (sectionBg === "white") headerBg = "bg-white"
  }
  return (
    <Layout noise={false} headerBg={headerBg} hideCTA dark={isDark}>
      <Seo {...wpPage?.seo} />
      <FlexibleContent content={wpPage.page?.flexibleContent} />
    </Layout>
  )
}

export default Default

export const pageQuery = graphql`
  query Default($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      id
      page {
        ...FlexibleContent
      }
    }
  }
`
