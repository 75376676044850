import { motion, useAnimation } from "framer-motion"
import { graphql } from "gatsby"
import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { Icon, IconType } from "~/components/FlexibleLayouts/elements/Icons"
import { Links } from "~/components/FlexibleLayouts/elements/Link"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  BodyCopy,
  BodyCopySizeOption,
  Heading,
  HeadingSizeOption,
  SecondarySubheading,
  Subheading,
  TertiarySubheading,
} from "~/components/FlexibleLayouts/elements/Typography"

export interface DefaultHeroProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  subheadingType: string
  bodySize: string
  showShareholderInfo: boolean
}

export const DefaultHero = ({
  coreCopyStack,
  section,
  subheadingType,
  bodySize,
  showShareholderInfo,
}: DefaultHeroProps) => {
  const controls = useAnimation()
  const { ref, inView } = useInView({ triggerOnce: true })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    } else {
      controls.start("hidden")
    }
  }, [controls, inView])

  let headingSize = HeadingSizeOption.Large
  if (bodySize === "medium" || bodySize === "large") {
    headingSize = HeadingSizeOption.XLarge
  }

  const subheadingAnimation = {
    animate: controls,
    initial: "hidden",
    variants: {
      hidden: { opacity: 0, y: -5 },
      visible: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.8,
          delay: 0.1,
        },
      },
    },
  }

  const headingAnimation = {
    animate: controls,
    initial: "hidden",
    variants: {
      hidden: { opacity: 0, x: 5 },
      visible: {
        opacity: 1,
        x: 0,
        transition: {
          opacity: {
            duration: 1,
          },
          duration: 0.8,
          delay: 0,
        },
      },
    },
  }

  const bodyAnimation = {
    animate: controls,
    initial: "hidden",
    variants: {
      hidden: { opacity: 0, y: 5 },
      visible: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 1,
          delay: 0.2,
        },
      },
    },
  }

  const buttonAnimation = {
    animate: controls,
    initial: "hidden",
    variants: {
      hidden: { opacity: 0, y: 5 },
      visible: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.8,
          delay: 0.4,
          delayChildren: 0.04,
        },
      },
    },
  }

  return (
    <Section {...section}>
      <div ref={ref} className="pt-[30px] md:pt-[60px] xl:pt-0">
        {coreCopyStack.subheading && subheadingType === "primary" ? (
          <motion.div {...subheadingAnimation} className="pt-12 mb-5">
            <Subheading> {coreCopyStack.subheading}</Subheading>
          </motion.div>
        ) : coreCopyStack.subheading && subheadingType === "secondary" ? (
          <motion.div {...subheadingAnimation} className="pt-12 mb-5">
            <SecondarySubheading>
              {coreCopyStack.subheading}
            </SecondarySubheading>
          </motion.div>
        ) : (
          coreCopyStack.subheading && (
            <motion.div
              {...subheadingAnimation}
              className="min-h-[50px] xl:min-h-[140px]"
            >
              <TertiarySubheading Type="h2">
                <span className="inline-block mr-4 relative top-[4px]">
                  <Icon type={IconType.Cursor} />
                </span>
                {coreCopyStack.subheading}
              </TertiarySubheading>
            </motion.div>
          )
        )}
        <motion.div {...headingAnimation}>
          <Heading Type="h1" size={headingSize}>
            {coreCopyStack.heading}
          </Heading>
        </motion.div>
        {coreCopyStack.text && (
          <motion.div {...bodyAnimation} className="pt-4 md:pt-8">
            <BodyCopy
              size={
                bodySize === "small"
                  ? BodyCopySizeOption.Small
                  : bodySize === "medium"
                  ? BodyCopySizeOption.Medium
                  : bodySize === "large"
                  ? BodyCopySizeOption.Large
                  : null
              }
            >
              {coreCopyStack.text}
            </BodyCopy>
          </motion.div>
        )}
        {coreCopyStack.links && (
          <motion.div
            {...buttonAnimation}
            className="pt-[8px] md:pt-[20px] xl:pt-[50px]"
          >
            <Links links={coreCopyStack.links} />
          </motion.div>
        )}
        {showShareholderInfo && <ShareholderInfo />}
      </div>
    </Section>
  )
}

const priceFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

export const ShareholderInfo = () => {
  return (
    <div className="mt-4 lg:mt-10">
      <iframe
        loading="lazy"
        src="https://ir2.flife.de/data/future_plc/ticker.php"
        width="300"
        height="150"
        frameBorder="0"
      ></iframe>
    </div>
  )
}

export const layoutQueryFragment = graphql`
  fragment DefaultHero on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_DefaultHero {
      fieldGroupName
      ...coreCopyStackDefaultHero
      ...sectionDefaultHero
      subheadingType
      bodySize
      showShareholderInfo
    }
  }
`
