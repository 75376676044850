import { Link } from "gatsby"
import React, { useState } from "react"
import Marquee from "react-fast-marquee"
import { useWindowSize } from "react-use"
import { Icon, IconType } from "~/components/FlexibleLayouts/elements/Icons"

import {
  BodyCopySizeOption,
  CoreCopyStack,
} from "~/components/FlexibleLayouts/elements/Typography"
import Image from "~/components/Image"

export const Card = ({
  card,
  scrollText,
  scrollClass = "xl:text-[268px]",
  bodySize = BodyCopySizeOption.MediumWide,
  headingClass = "",
  textContainerClass = "md:px-[58px] md:py-[55px]",
  linkClass = "md:!text-[21px] md:!leading-[26px]",
  maxWidth = "max-w-[384px]",
}) => {
  // const [onHover, setOnHover] = useState(false)

  let paddingClass = "lg:pl-[84px]"
  // Swap around so lice doesn't appear by default
  // if (scrollText === "Licensing") {
  //   paddingClass = ""
  //   scrollText = "sing Licen"
  // }

  const link = card?.coreCopyStack?.links
    ? card?.coreCopyStack?.links[0]?.link
    : null

  // const { width } = useWindowSize()

  return (
    <Link
      className="block u-animate-button-arrow u-animate-trigger-opacity"
      to={
        (card?.coreCopyStack?.links &&
          card?.coreCopyStack?.links[0]?.link?.url) ||
        "#"
      }
      style={{ cursor: "pointer" }}
    >
      <div
      // onMouseEnter={() => setOnHover(true)}
      // onMouseLeave={() => setOnHover(false)}
      >
        {scrollText && (
          <div className="bg-slate bg-slate-invert-on-slate">
            <Marquee
              className={`overflow-hidden text-[100px] lg:text-[200px] ${scrollClass} leading-[1.604] font-bold pl-6 ${paddingClass} pointer-events-none`}
              gradient={false}
              play={false}
              speed={100}
            >
              <div
                // style={{
                //   WebkitTextStrokeWidth: "1px",
                //   WebkitTextStrokeColor: "#D5D2CA",
                // }}
                // className={` ${
                //   onHover ? "text-beige" : "text-transparent"
                // } mr-6 `}

                className="text-beige"
              >
                {scrollText}
              </div>
            </Marquee>
          </div>
        )}
        {card?.image && (
          <div className="relative img-fade">
            <Image image={card?.image} />
          </div>
        )}

        <div
          className={`w-full text-white bg-slate bg-slate-invert-on-slate px-6 py-12 mt-[-1px]  ${textContainerClass} `}
        >
          <div className="flex flex-col justify-between h-full">
            <div className={maxWidth}>
              <CoreCopyStack
                bodySize={bodySize}
                heading={card?.coreCopyStack?.heading}
                subheading={card?.coreCopyStack?.subheading}
                text={card?.coreCopyStack?.text}
                headingClass={headingClass}
              />
              {link && (
                <div className="md:opacity-0 u-animate-target-opacity">
                  <span
                    className={`inline-block font-semibold text-[14px] leading-[14px] md:text-[15px] md:leading-[15px] pt-6 ${linkClass}`}
                  >
                    {link.title}
                    <span
                      className={`u-animate-target inline-block ml-2 relative`}
                    >
                      <Icon type="arrow-right" />
                    </span>
                  </span>
                </div>
              )}
            </div>
            {card?.icon && (
              <div className="flex items-end justify-end mt-10 lg:mt-[78px] ">
                <Image image={card?.icon} className="max-w-[120px] h-full" />
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  )
}
