import React from "react"
import styled from "styled-components"
import tw from "twin.macro"

const StratTable = styled.div`
  ${tw`w-full`}

  table {
    ${tw`bg-light-grey rounded-[6px]`}

    tr {
      ${tw`w-full`}
    }
    th {
      ${tw`px-2 py-[42px] md:px-[43px]`}
    }
    td {
      ${tw`px-4 py-[32px]  md:px-[43px] `}
      vertical-align: baseline;
      border-top: 1px solid #d5d2ca;
      span {
        ${tw`max-w-[293px] `}
      }
    }
  }

  .mobile-table {
    tr {
      th {
        ${tw`w-1/3 px-6 py-5`}
        vertical-align: baseline;
        display: none;
      }
      td {
        vertical-align: baseline;

        ${tw`w-2/3 px-6 py-5`}
      }
      .font-extrabold {
        margin-bottom: 5px;
      }
    }
    tr:first-child td {
      border-top: 0px;
    }
  }
`

function StrategyTable({ table, showMore }) {
  return (
    <StratTable className="overflow-scroll scrollbar-hidden">
      <table className="hidden w-full lg:inline-table px-[43px] ">
        <thead className="sticky">
          <tr>
            <th>
              <TableHeading label="Date" />
            </th>
            <th>
              <TableHeading label="Name" />
            </th>
            <th className="flex flex-col items-start justify-center">
              <TableHeading label="Content/Capability" />
            </th>
            {table?.some(t => t?.multiples) && (
              <th>
                <TableHeading label="Multiples" />
              </th>
            )}
          </tr>
        </thead>
        <tbody className="text-14">
          {table?.slice(0, showMore)?.map((row, i) => (
            <React.Fragment>
              <tr key={`row${i}`}>
                <td className="font-extrabold uppercase ">{row?.date}</td>
                <td
                  className="font-semibold"
                  dangerouslySetInnerHTML={{ __html: row?.name }}
                />
                <td>
                  {row?.contentAndCapability?.map((c, i, arr) => (
                    <React.Fragment key={`${c?.text}${i}`}>
                      <div
                        className={`flex items-start ${
                          i !== arr?.length - 1 && "mb-1"
                        } `}
                      >
                        <div className="block max-w-[287px]">
                          <span className="font-semibold ">
                            {c?.type === "content"
                              ? "Content: "
                              : "Capability: "}
                          </span>
                          {c?.text}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </td>
                {row?.multiples && (
                  <>
                    <td className="">
                      {row?.multiples?.map((multi, i) => (
                        <div
                          key={`multi${i}`}
                          className="mb-3 font-semibold"
                          dangerouslySetInnerHTML={{ __html: multi?.content }}
                        />
                      ))}
                    </td>
                    <div className="w-full h-px bg-beige"></div>
                  </>
                )}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <div className="flex flex-wrap lg:hidden">
        {table?.slice(0, showMore)?.map((row, i) => (
          <React.Fragment key={`table${i}`}>
            <table className="w-full mb-3 mobile-table">
              <tr>
                <td className="font-extrabold uppercase ">
                  <TableHeading label="Date" />
                  {row?.date}
                </td>
              </tr>
              <tr>
                <th>
                  <TableHeading label="Name" />
                </th>
                <td className="font-semibold">
                  <TableHeading label="Name" />
                  <div dangerouslySetInnerHTML={{ __html: row?.name }} />
                </td>
              </tr>
              <tr>
                <td>
                  <TableHeading label="Content/Capability" />
                  {row?.contentAndCapability?.map((c, i, arr) => (
                    <React.Fragment key={`${c?.text}${i}`}>
                      <div
                        className={`flex items-start ${
                          i !== arr?.length - 1 && "mb-5"
                        } `}
                      >
                        <div className="block max-w-[287px]">
                          <span className="font-semibold ">
                            {c?.type === "content"
                              ? "Content: "
                              : "Capability: "}
                          </span>
                          {c?.text}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </td>
              </tr>
              {row?.multiples && (
                <tr>
                  <td className="">
                    <TableHeading label="Multiples" />
                    {row?.multiples?.map((multi, i) => (
                      <div
                        key={`multi${i}`}
                        className="mb-3 font-semibold"
                        dangerouslySetInnerHTML={{ __html: multi?.content }}
                      />
                    ))}
                  </td>
                </tr>
              )}
            </table>
          </React.Fragment>
        ))}
      </div>
    </StratTable>
  )
}

export const TableHeading = ({ label }) => (
  <div className="text-[12px] font-extrabold  rounded-[6px] bg-black px-[14px] py-[4px] text-white uppercase leading-[1.569] max-w-max">
    {label}
  </div>
)

export default StrategyTable
