import { useAnimation } from "framer-motion"
import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import {
  FourColumnGrid,
  TwoColumnGridAlternateVersion,
} from "~/components/FlexibleLayouts/elements/Grid"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStackProps,
  Heading,
  SecondaryCopyStackProps,
  Subheading,
} from "~/components/FlexibleLayouts/elements/Typography"
import Image from "~/components/Image"
import { TwoColumnGridListItem } from "./TwoColumnGridList"

export interface TwoColMediaWithListProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  alternateVerison: TwoColumnGridAlternateVersion
  list: {
    secondaryCopyStack: SecondaryCopyStackProps[]
  }
  noContainer: boolean
  paddingOverwrite: string
  twoColMedia: any
}

export const TwoColMediaWithList = ({
  twoColMedia,
  section,
  list,
  noContainer = false,
  paddingOverwrite,
}: TwoColMediaWithListProps) => {
  const controls = useAnimation()
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    } else {
      controls.start("hidden")
    }
  }, [controls, inView])
  return (
    <Section
      {...section}
      noContainer={noContainer}
      paddingOverwrite={paddingOverwrite}
    >
      <div ref={ref}>
        <div className="w-full md:mb-[155px]">
          <div className="flex flex-col items-center w-full xl:justify-between xl:flex-row">
            <div className="xl:max-w-[588px]">
              <Subheading>{twoColMedia?.subheading}</Subheading>
              <div className="mt-[25px]">
                <Heading>{twoColMedia?.heading}</Heading>
              </div>
              <div
                className="text-[17px] font-medium leading-[30px] mt-[15px]"
                dangerouslySetInnerHTML={{ __html: twoColMedia?.text }}
              />
            </div>
            <div className="lg:max-w-[300px] xl:max-w-none">
              <Image image={twoColMedia?.image} />
            </div>
          </div>
        </div>
        <FourColumnGrid>
          {list.map((listItem, i) => (
            <TwoColumnGridListItem
              controls={controls}
              index={i}
              key={i}
              {...listItem}
            />
          ))}
        </FourColumnGrid>
      </div>
    </Section>
  )
}
