import React, { Fragment, useEffect, useState } from "react"
import { graphql } from "gatsby"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import { TwoColumnGrid } from "~/components/FlexibleLayouts/elements/Grid"
import {
  CoreCopyStack,
  CoreCopyStackProps,
  CoreCopyStackStringFragment,
} from "~/components/FlexibleLayouts/elements/Typography"
import { NumberedList } from "~/components/FlexibleLayouts/elements/List"
import { IconType } from "../elements/Icons"
import { Link, LinkType } from "../elements/Link"
import { AnimatePresence, motion } from "framer-motion"
import { Modal } from "./TwoColumnGridList"

export interface TwoColumnListProps {
  coreCopyStack: CoreCopyStackProps
  list: string[]
  section: SectionProps
  paddingOverwrite: string
  noContainer: boolean
  modal: any
  modalButton: string
}

export const TwoColumnList = ({
  coreCopyStack,
  list,
  section,
  paddingOverwrite,
  noContainer = false,
  modal,
  modalButton,
}: TwoColumnListProps) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <Section
      {...section}
      paddingOverwrite={paddingOverwrite}
      noContainer={noContainer}
    >
      <TwoColumnGrid>
        <div className="sticky md:pr-4 lg:pr-12 top-10">
          <CoreCopyStack {...coreCopyStack} />
          {modalButton === "yes" && (
            <div
              className="mt-6 cursor-pointer"
              onClick={() => setShowModal(true)}
            >
              <Link
                link={{ title: "Learn More" }}
                icon={IconType.ArrowRight}
                type={LinkType.Button}
              />
            </div>
          )}
        </div>
        <Fragment>
          <NumberedList>
            {list.map((listItem, i) => (
              <Fragment key={i}>
                <div dangerouslySetInnerHTML={{ __html: listItem?.text }} />
              </Fragment>
            ))}
          </NumberedList>
        </Fragment>
      </TwoColumnGrid>
      <AnimatePresence>
        {showModal && <Modal {...modal} setShowModal={setShowModal} />}
      </AnimatePresence>

      <AnimatePresence>
        {showModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-40"
            onClick={() => setShowModal(false)}
          ></motion.div>
        )}
      </AnimatePresence>
    </Section>
  )
}

export const layoutQueryFragment = graphql`
  fragment TwoColumnList on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_TwoColumnList {
      fieldGroupName
      ...coreCopyStackTwoColumnList
      list {
        text
      }
      ...sectionTwoColumnList
    }
  }
`
