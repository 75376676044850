import { motion } from "framer-motion"
import React from "react"

function FinancialHighlight({
  className = "",
  numberPrefix,
  number,
  numberAffix,
  description,
  i,
  ...other
}) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.7, delay: (i + 1) / 12 }}
      className={`flex flex-col ${className} `}
      {...other}
    >
      <div className="flex items-start justify-between mb-3">
        <div className="u-h1a">
          {numberPrefix}
          {number}
          <span className="text-24 md:text-40">{numberAffix}</span>
        </div>
        <motion.div
          initial={{ y: 50 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.7, delay: (i + 1) / 12 }}
        >
          <svg
            className="md:mt-[0.8rem] max-h-[25px]"
            width="20"
            height="27"
            viewBox="0 0 20 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 10.1054L10 0L0 10.1054L2.09037 12.2181L8.50702 5.73143V27H11.4929V5.73143L17.9095 12.2181L20 10.1054Z"
              fill="#1ce547"
            />
          </svg>
        </motion.div>
      </div>
      <div className="h-[10px] w-full bg-brown mb-[10px] md:mb-[38px] z-10 overflow-hidden"></div>
      <p
        className="text-14 md:text-18 leading-[1.309] font-semibold"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </motion.div>
  )
}

export default FinancialHighlight
