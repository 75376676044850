import React, { Fragment, useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion"
import { set } from "lodash"
import { useWindowSize } from "react-use"

import Image from "~/components/Image"

export const HomeHero = ({
  config,
  section,
  imageGallery1,
  imageGallery2,
  imageGallery3,
}) => {
  const [windowSize, setWindowSize] = useState({ width: 320, height: 320 })
  const [init, setInit] = useState(false)

  React.useEffect(() => {
    function doResize() {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight })
      setInit(true)
    }
    window.addEventListener("resize", doResize)
    let timeout = window.setTimeout(doResize, 0)
    return () => {
      window.clearTimeout(timeout)
      window.removeEventListener("resize", doResize)
    }
  }, [])

  let scale = 1
  const desktopWidth = 1305
  const desktopHeight = 619
  if (windowSize.width < desktopWidth) {
    scale = windowSize.width / (desktopWidth + 110)
  }

  if (!init) return <div style={{ paddingBottom: "70%" }}></div>

  return (
    <Section {...section} noContainer paddingOverwrite="xl:!pt-[125px]">
      <div
        className="relative pt-6 cursor-default md:pt-12 xl:pt-4"
        style={{ perspective: "400px" }}
      >
        <div className="max-w-[1341px] mx-auto overflow-hidden">
          <div
            className="mx-auto overflow-hidden"
            style={{
              width: desktopWidth * scale,
              height: desktopHeight * scale,
            }}
          >
            <div
              style={{
                position: "relative",
                width: desktopWidth,
                height: desktopHeight,
                margin: "auto",
                transform: `scale(${scale})`,
                transformOrigin: "0 0",
                perspective: "400px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                }}
              >
                <HomeHeroCursor className="top-0 left-0 transform rotate-180" />
                <HomeHeroCursor className="top-0 right-0 -rotate-90" />
                <HomeHeroCursor className="bottom-0 left-0 rotate-90" />
                <HomeHeroCursor className="bottom-0 right-0" />
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 60,
                  left: 70,
                  right: 70,
                  bottom: 80,
                  fontSize: 125,
                  fontWeight: 800,
                  lineHeight: 1,
                  letterSpacing: "0.022em",
                  textTransform: "uppercase",
                  transformOrigin: "50% 50%",
                  perspective: "400px",
                }}
              >
                {config === "home" && (
                  <Fragment>
                    <div>The Global</div>
                    <div>Platform For</div>
                    <div style={{ paddingLeft: 360 }}>Specialist</div>
                    <div style={{ paddingLeft: 360 }}>Media</div>
                    <HomeHeroBannerImages
                      bottom={-3}
                      left={0}
                      width={325}
                      height={210}
                      images={imageGallery1}
                      animationDelay={2000}
                    />
                    <HomeHeroBannerImages
                      top={17}
                      right={-10}
                      width={275}
                      height={90}
                      images={imageGallery2}
                      animationDelay={2000}
                    />
                    <HomeHeroBannerLogos
                      images={imageGallery3}
                      bottom={-15}
                      right={0}
                    />
                  </Fragment>
                )}
                {config === "about" && (
                  <Fragment>
                    <div style={{ paddingLeft: 80 }}>Connectors.</div>
                    <div className="font-medium" style={{ paddingLeft: 360 }}>
                      Creators.
                    </div>
                    <div>Experience</div>
                    <div style={{ paddingLeft: 450 }}>Makers.</div>
                    <HomeHeroBannerImages
                      top={175}
                      left={20}
                      width={320}
                      height={90}
                      images={imageGallery1}
                      animationDelay={2000}
                    />
                    <HomeHeroBannerImages
                      top={320}
                      right={0}
                      width={270}
                      height={90}
                      images={imageGallery2}
                      animationDelay={2000}
                    />
                    <HomeHeroBannerLogos
                      images={imageGallery3}
                      bottom={-20}
                      left={120}
                    />
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

const HomeHeroBannerImages = ({
  images,
  width,
  height,
  top,
  left,
  bottom,
  right,
  animationDelay,
}) => {
  const [active, setActive] = useState(0)

  useEffect(() => {
    let timeout
    const loop = () => {
      timeout = setTimeout(() => {
        setActive(active => (active === images.length - 1 ? 0 : active + 1))
        loop()
      }, 2000 + animationDelay)
    }
    loop()
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [])

  return (
    <div
      style={{
        top,
        right,
        bottom,
        left,
        width,
        height,
        position: "absolute",
        backgroundSize: "cover",
      }}
    >
      <AnimatePresence>
        {images.map(
          (image, i) =>
            active === i && (
              <motion.div
                key={i}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{
                  objectFit: "contain",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Image
                  image={image}
                  objectFit="cover"
                  className="absolute w-full h-full image-absolute"
                />
              </motion.div>
            )
        )}
      </AnimatePresence>
    </div>
  )
}

const HomeHeroBannerLogos = ({ images, top, bottom, left, right }) => {
  const [active, setActive] = useState(0)

  useEffect(() => {
    let timeout
    const loop = () => {
      timeout = setTimeout(() => {
        setActive(active => (active === images.length - 1 ? 0 : active + 1))
        loop()
      }, 4000)
    }
    loop()
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [])

  return (
    <div
      style={{
        top,
        left,
        bottom,
        right,
        width: 300,
        position: "absolute",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div>(</div>
      <div
        style={{
          width: 190,
          height: 100,
          display: "inline-flex",
          top: 10,
          left: -2,
          position: "relative",
          perspective: "400px",
        }}
      >
        <AnimatePresence>
          {images.map(
            (logo, i) =>
              active === i && (
                <motion.div
                  key={i}
                  initial={{ opacity: 0, rotateY: 65, x: 90 }}
                  animate={{ opacity: 1, rotateY: 0, x: 0 }}
                  exit={{ opacity: 0, rotateY: -65, scale: 0.75, x: -90 }}
                  style={{
                    objectFit: "contain",
                    objectPosition: "center",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                  }}
                  className="flex justify-center"
                >
                  <Image
                    image={logo}
                    objectFit="contain"
                    className="absolute w-full h-full image-absolute max-w-[165px] object-contain object-center "
                  />
                </motion.div>
              )
          )}
        </AnimatePresence>
      </div>
      <div>)</div>
    </div>
  )
}

const HomeHeroCursor = ({ className = "" }) => {
  return (
    <div className={`absolute ${className}`}>
      <div className="bg-red" style={{ width: 60, height: 17 }}></div>
      <div className="bg-red" style={{ width: 17, height: 43 }}></div>
    </div>
  )
}
const HoverLetter = ({ char = "X" }) => {
  const [active, setActive] = useState(false)
  let timeout
  const onMouseOver = () => {
    if (timeout) clearTimeout(timeout)
    setActive(true)
    timeout = setTimeout(() => {
      setActive(false)
    }, 700)
  }
  useEffect(() => {
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [])
  return (
    <span
      char={char}
      onMouseOver={onMouseOver}
      className={`hero-text ${active ? "active" : ""}`}
    >
      {char}
    </span>
  )
}

const HoverLetters = ({ string }) => {
  return (
    <>
      {string.split("").map((letter, i) => (
        <HoverLetter key={i} char={letter} />
      ))}
    </>
  )
}

export const layoutQueryFragment = graphql`
  fragment HomeHero on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_HomeHero {
      fieldGroupName
      config
      imageGallery1 {
        ...Image
      }
      imageGallery2 {
        ...Image
      }
      imageGallery3 {
        ...Image
      }
      ...sectionHomeHero
    }
  }
`
