import { motion, useAnimation } from "framer-motion"
import { graphql } from "gatsby"
import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import {
  TwoColumnGridAlternateVersion,
  TwoColumnGridWithAlternate,
} from "~/components/FlexibleLayouts/elements/Grid"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStackProps,
  Heading,
  SecondaryCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"

export interface TwoColumnStatsGridProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  alternateVerison: TwoColumnGridAlternateVersion
  list: TwoColumnStatsGridItemProps[]
}

export const TwoColumnStatsGridAlt = ({
  coreCopyStack,
  section,
  list,
}: TwoColumnStatsGridProps) => {
  const controls = useAnimation()
  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    } else {
      controls.start("hidden")
    }
  }, [controls, inView])
  return (
    <Section {...section}>
      <div ref={ref}>
        <TwoColumnGridWithAlternate
          className="max-w-[823px]"
          main={
            <Heading className="!normal-case !text-[24px] md:!text-[30px] !font-semibold !leading-[1.214] mb-10 md:mb-24">
              {coreCopyStack?.heading}
            </Heading>
          }
          inner={list.map((listItem, i) => (
            <TwoColumnStatsGridItem
              key={i}
              {...listItem}
              controls={controls}
              index={i}
            />
          ))}
          alternateVersion={TwoColumnGridAlternateVersion.FourColumn}
        ></TwoColumnGridWithAlternate>
      </div>
    </Section>
  )
}

export interface TwoColumnStatsGridItemProps {
  stat: string
  statAffix: string
  statPrefix: string
  text: string
  controls: any
}

export const TwoColumnStatsGridItem = ({
  stat,
  text,
  statAffix,
  statPrefix,
  controls,
  index,
}: SecondaryCopyStackProps) => (
  <motion.article
    animate={controls}
    initial="hidden"
    variants={{
      hidden: { opacity: 0, x: -25 },
      visible: {
        opacity: 1,
        x: 0,
        transition: {
          duration: 0.8,
          delay: index * 0.3,
        },
      },
    }}
    className="md:max-w-[260px] mb-8 last:mb-0 md:mb-0"
  >
    <div className="flex items-start ">
      <div className="text-[24px] lg:mt-[-1rem] xl:mt-[-1.5rem] lg:text-[56px] text-red  font-medium tracking-[-0.025em] self-end h-auto">
        {statPrefix}
      </div>
      <h6 className="text-[50px] leading-[50px] lg:text-[60px] lg:leading-[60px] xl:text-[111px] xl:leading-[80px] text-red font-semibold">
        {stat}
      </h6>
      <div className="text-[20px] lg:mt-[-1rem] xl:mt-[-1.5rem] lg:text-[56px] text-red  font-medium tracking-[-0.025em] ml-[7px]">
        {statAffix}
      </div>
    </div>

    <motion.div
      animate={controls}
      initial="hidden"
      variants={{
        hidden: { opacity: 0, width: 0 },
        visible: {
          opacity: 1,
          width: "100%",
          transition: {
            duration: 0.6,
            delay: index * 0.3 + 0.4,
          },
        },
      }}
      className="h-[1px] bg-beige mt-[10px] md:mt-[30px]"
    ></motion.div>
    <motion.div className=" text-[15px] leading-[19px] pt-[5px] md:pt-[30px]  font-semibold">
      {text}
    </motion.div>
  </motion.article>
)

export const layoutQueryFragment = graphql`
  fragment TwoColumnStatsGridAlt on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_TwoColumnStatsGridAlt {
      fieldGroupName
      ...coreCopyStackTwoColumnStatsGridAlt
      ...sectionTwoColumnStatsGridAlt

      list {
        statPrefix
        stat
        statAffix
        text
      }
    }
  }
`
