import React, { useRef, useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Scrollbar, Pagination } from "swiper"
import "swiper/components/scrollbar/scrollbar.scss"
import "swiper/swiper.scss"

import Image from "../Image"
import TimelineButton from "./buttons/TimelineButton"
import { AnimatePresence, motion } from "framer-motion"

const CareersSliderContainer = styled.div`
  .swiper-pagination {
    ${tw`flex w-1/2 bg-black bg-opacity-30`}
    height: 1px;

    bottom: 0;

    top: unset;
    position: absolute;

    .swiper-pagination-progressbar {
      .swiper-pagination-progressbar-opposite {
        right: 0 !important;
        left: unset;
      }
    }

    .swiper-pagination-progressbar-fill {
      ${tw`bg-black`}
      height: 2px;
    }
  }
`

function CareersSlider({ sliders }) {
  // console.log(sliders)

  SwiperCore.use([Scrollbar, Navigation, Pagination])
  const careerSlider = useRef(null)

  const [activeTab, setActiveTab] = useState(0)

  // console.log(activeTab)
  return (
    <CareersSliderContainer>
      <div className="relative flex flex-col items-center lg:flex-row">
        <div className="w-full lg:w-1/2 lg:mr-20 ">
          <AnimatePresence exitBeforeEnter>
            {sliders?.map(
              (image, i) =>
                i === activeTab && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.4, ease: "easeInOut" }}
                    key={`careerImage${i}`}
                    className="max-w-[418px] mx-auto lg:ml-0 lg:mr-auto"
                  >
                    <Image image={image?.image} />
                  </motion.div>
                )
            )}
          </AnimatePresence>
        </div>
        <Swiper
          onActiveIndexChange={i => setActiveTab(i.activeIndex)}
          className="relative z-40 flex flex-col w-full lg:w-1/2"
          slidesPerView={`1`}
          ref={careerSlider}
          grabCursor="1"
          pagination={{
            type: "progressbar",
          }}
          //   style={{ overflowX: "hidden", overflowY: "" }}
        >
          {sliders?.map((slider, i) => (
            <SwiperSlide
              key={`careerSlider${i}`}
              className="flex justify-between pt-6 pb-12 md:py-12"
              style={{ height: "auto" }}
            >
              <div>
                <div className="flex">
                  <svg
                    className="md:w-[54px] md:h-[54px] w-[25px] h-[25px]"
                    width="54"
                    height="54"
                    viewBox="0 0 54 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="54" height="54" fill="#231F20" />
                    <path
                      d="M23.8283 26.4887C24.9385 27.2312 25.4936 28.3048 25.4936 29.7095C25.4936 30.8533 25.1367 31.7964 24.4231 32.5389C23.7094 33.2814 22.8074 33.6526 21.7171 33.6526C20.706 33.6526 19.8337 33.2914 19.1002 32.569C18.3667 31.8466 18 30.8934 18 29.7095C18 28.6459 18.3667 27.5723 19.1002 26.4887L22.5497 21.1309H26.1478L23.8283 26.4887ZM32.898 26.4887C34.0081 27.2312 34.5632 28.3048 34.5632 29.7095C34.5632 30.8533 34.2064 31.7964 33.4927 32.5389C32.779 33.2814 31.877 33.6526 30.7867 33.6526C29.7756 33.6526 28.9034 33.2914 28.1699 32.569C27.4364 31.8466 27.0696 30.8934 27.0696 29.7095C27.0696 28.6459 27.4364 27.5723 28.1699 26.4887L31.6193 21.1309H35.2174L32.898 26.4887Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="text-black text-[18px] md:text-[28px] leading-[1.239] tracking-[0.02em]  mt-7 font-semibold">
                  {slider?.quote}
                </div>
                <div className="mt-[10px] md:mt-[37px] uppercase text-[14px] leading-[1.604]">
                  <div className="font-bold ">{slider?.name}</div>
                  <div className="font-medium opacity-40">
                    {slider?.jobTitle}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="flex items-center w-[150px] max-w-[40%] md:mb-0 absolute bottom-[-1.5rem] lg:bottom-[-0.5rem] right-0">
          <TimelineButton
            className="z-50 mr-2 cursor-pointer md:mr-4 h-14"
            onClick={() => careerSlider.current.swiper.slidePrev()}
            disabled={activeTab === 0}
          />
          <TimelineButton
            onClick={() => careerSlider.current.swiper.slideNext()}
            className="z-50 rotate-180 cursor-pointer h-14 "
            disabled={activeTab === sliders?.length - 1}
          />
        </div>
      </div>
    </CareersSliderContainer>
  )
}

export default CareersSlider
