import { graphql } from "gatsby"
import React from "react"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
  HeadingSizeOption,
} from "~/components/FlexibleLayouts/elements/Typography"
import CareersSlider from "~/components/sliders/CareersSlider"

export interface PeopleSliderProps {
  coreCopyStack: CoreCopyStackProps
  section: SectionProps
  sliders: any[]
}

export const PeopleSlider = ({
  coreCopyStack,
  section,
  sliders,
}: PeopleSliderProps) => (
  <Section {...section}>
    <div className="max-w-[714px]">
      <CoreCopyStack
        headingSize={HeadingSizeOption.Medium}
        {...coreCopyStack}
      />
    </div>
    <div className="mt-12 md:mt-20">
      <CareersSlider sliders={sliders} />
    </div>
  </Section>
)

export const layoutQueryFragment = graphql`
  fragment PeopleSlider on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_PeopleSlider {
      fieldGroupName
      ...coreCopyStackPeopleSlider
      ...sectionPeopleSlider
      sliders {
        image {
          ...Image
        }
        name
        jobTitle
        quote
      }
    }
  }
`
